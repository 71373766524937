import * as constants from "@discovery/client-core/webpack/constants";
//prettier-ignore
export const DEVELOPMENT_MODE = process.env.BUILD_MODE === constants.DEVELOPMENT;
//prettier-ignore
export const PRODUCTION_MODE = process.env.BUILD_MODE === constants.PRODUCTION;
export const LOG_WHY_DID_YOU_RENDER = false;
// Can we guarantee Environmnet variables with nix ?
export const SONIC_REALM = process.env.SONIC_REALM;
export const SONIC_ENDPOINT = process.env.SONIC_ENDPOINT;
//prettier-ignore
export const USE_REALM_SERVICE = Boolean(process.env.USE_REALM_SERVICE);
export const SONIC_PROD_ENDPOINT = "ap2-prod-direct.mercury.dnitv.com";
export const SONIC_DPLUS_INDIA_REALM = "dplusindia";
//prettier-ignore
export const AUTH_PROD_DOMAIN = "auth.discoveryplus.in";
export const AUTH_DOMAIN = process.env.AUTH_DOMAIN;
export const VERSION = process.env.VERSION;
export const BRANCH_KEY_DEV = "key_test_pfJUtcWHw28gCAar6PDEKhbgwukzrNYK";
export const BRANCH_KEY_PROD = "key_live_nePKFl6HD81jywfs0Vn4qaadCEoyCP8F";
export const adobeUrlDev = "https://assets.adobedtm.com/479fbb05b9cf/e003f0865ba2/launch-4120471cd0c8-development.min.js";
export const adobeUrlStaging = "https://assets.adobedtm.com/479fbb05b9cf/e003f0865ba2/launch-974288b579fb-staging.min.js";
export const adobeUrlProd = "https://assets.adobedtm.com/479fbb05b9cf/e003f0865ba2/launch-647e107b7937.min.js";

